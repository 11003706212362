/**
 *  Validation Rules
 * ---------------------------------------------------------------------------------------------------------------------
 *  Validations for the application form fields.
 */

export interface AppliedRules {
  required?: boolean;
  maxLength?: number;
  minLength?: number;
  email?: boolean;
  phone?: boolean;
  confirmValues?: any;
  aNumberRequired?: boolean;
  aSymbolRequired?: boolean;
  dateRange?: boolean;
  duplicateEmail?: boolean;
}

const validationRules = {
  /**
   * Validation Rule executor
   *
   * @param fieldName
   * @param appliedRules
   * @param v
   */
  rules: (fieldName: string, appliedRules: AppliedRules, v: any): string | null => {
    for (const [key, value] of Object.entries(appliedRules)) {
      switch (key) {
        case 'required':
          if (!(!!v && (typeof v !== 'string' || !!v.trim()))) {
            return `Please enter ${fieldName}!`;
          }
          break;
        case 'maxLength':
          if (!((!v || v.length < value))) {
            return `${fieldName} is too long!`;
          }
          break;
        case 'minLength':
          if (!((!v || v.length >= value))) {
            return `${fieldName} is too short!`;
          }
          break;
        case 'email':
          if (!(/.+@.+\..+/.test(v))) {
            return `Please enter a valid ${fieldName}!`;
          }
          break;
        case 'phone':
          if (!(!v ||/^\d+$/.test(v))) {
            return 'Only numbers are allowed here!';
          } else if (!(!v || v.length > 6)) {
            return 'Please enter a valid phone number!';
          } else if (!(!v || v.length < 14)) {
            return 'Please enter a valid phone number!';
          }
          break;
        case 'confirmValues':
          if (!((!!v && v === value))) {
            return `${fieldName} does not match!`;
          }
          break;
        case 'aNumberRequired':
          if (!(/^(?=.*[0-9]).*$/.test(v))) {
            return `${fieldName} should have at least one number!`;
          }
          break;
        case 'aSymbolRequired':
          if (!(/^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/.test(v))) {
            return `${fieldName} should have at least one symbol!`;
          }
          break;
        case 'dateRange':
          if (!((Array.isArray(v) && v.length === 2))) {
            return 'Please select a valid date range!';
          }
          break;
        case 'duplicateEmail':
          if (value) {
            return 'Email already exists! Please try to login.';
          }
          break;
      }
    }

    return null;
  },
};

export default validationRules;
