<template>
  <div class="el-input-text" :class="`--${variant} ${isFocused ? '--focused' : ''} ${validationError ? '--error' : ''}`">
    <div class="__input-text-inner">
      <div class="__input-field-wrapper">
        <span v-if="label" class="__input-label" @click="focusInputField">{{ label }}</span>
        <input ref="elInputFieldRef" v-model="inputValue" :type="type" :placeholder="placeholder" @keyup="updateModelValue" @focus="isFocused = true" @blur="isFocused = false; validateInput();">
        <div v-if="prependIcon" class="__prepend-icon-wrapper" @click="emit('clicked:prependIcon')">
          <img :src="prependIcon" alt="Input icon" data-not-lazy width="20" height="20">
        </div>
      </div>
    </div>
    <transition name="fade">
      <span v-if="validationError" :key="`input-error-message`" class="el-input-text__validation-error">{{ validationError }}</span>
    </transition>
  </div>
</template>

<script setup lang="ts">
import {PropType, Ref} from 'vue';
import validationRules, {AppliedRules} from '~/composables/validationRules';

// PROPS
const props = defineProps({
  modelValue: {
    type: [Number, String] as PropType<any>,
    default: null,
  },
  type: {
    type: String as PropType<'text' | 'number' | 'hidden' | 'tel' | 'button' | 'submit' | 'url' | 'email'>,
    default: 'text',
  },
  label: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  prependIcon: {
    type: [String, null] as PropType<object | null>,
    default: null,
  },
  variant: {
    type: String as PropType<'flat' | 'underlined'>,
    default: 'flat',
  },
  rules: {
    type: [Object, null] as PropType<{fieldName: string, rules: AppliedRules}>,
    default: null,
  },
  isValid: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
});

// DATA
const elInputFieldRef: Ref<HTMLElement | null> = ref(null);
const inputValue: Ref<number | string | null> = ref(props.modelValue);
const isFocused: Ref<boolean> = ref(false);
const validationError: Ref<string | null> = ref(null);

// EMIT Definitions
//----------------------------------------------------------------------------------------------------------------------
const emit = defineEmits<{
  (event: 'update:modelValue', payload: string | number | null): void;
  (event: 'clicked:prependIcon', payload: void): void;
  (event: 'update:isValid', payload: string[]): void;
}>();

/**
 * @_MOUNTED Hook
 */
onMounted(() => {
  inputValue.value = props.modelValue;
});

/**
 * @_WATCH modelValue
 */
watch(() => props.modelValue, () => {
  if (props.modelValue === inputValue.value) {
    return;
  }
  inputValue.value = props.modelValue;
});

/**
 * @_WATCH isValid
 */
watch(() => props.isValid, () => {
  validateInput();
  if (validationError.value?.length) {
    const localIsValid = props.isValid;
    localIsValid?.push(validationError.value);
    emit('update:isValid', localIsValid);
  }
});

/**
 * Validate input
 */
function validateInput () {
  if (props.rules?.rules && props.rules?.fieldName) {
    validationError.value = validationRules.rules(props.rules?.fieldName, props.rules?.rules, inputValue.value);
  }
}


/**
 * Update model value
 */
function updateModelValue () {
  emit('update:modelValue', inputValue.value);
}

/**
 * Focus input field
 */
function focusInputField () {
  if (elInputFieldRef.value) {
    elInputFieldRef.value.focus();
  }
}
</script>

<style lang="scss" scoped>
.el-input-text {
  height: 100%;
  width: 100%;

  .__input-text-inner {
    height: 100%;
    width: 100%;
    display: inline-flex;
    align-items: center;
    padding: 0 12px;

    .__input-field-wrapper {
      position: relative;
      width: 100%;

      input {
        width: 100%;
        border: none;
        color: rgb(var(--text-darken-1));
        background: transparent;
        font-size: 16px;

        &:focus {
          outline: none;
        }
      }

      .__input-label {
        color: rgb(var(--text-lighter-1));
        font-size: 14px;
        padding-left: 2px;
        padding-bottom: 5px;
        display: block;
      }

      .__prepend-icon-wrapper {
        align-items: flex-end;
        position: absolute;
        right: 0;
        bottom: 5px;
        cursor: pointer;

        img {
          width: 20px;
          height: auto;
        }
      }
    }
  }

  .el-input-text__validation-error {
    display: block;
    color: #d30101;
    font-size: 12px;
    padding-left: 12px;
    padding-top: 5px;
    margin-top: -5px;
  }

  &.--underlined {
    .__input-field-wrapper {
      border-bottom: 1px solid #c7c7c7;

      &::after {
        content: "";
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 0;
        width: 0;
        margin: auto;
        height: 2px;
        background: rgba(45, 66, 183, 0.6); /* Change this to match your theme color */
        transition: width 0.3s ease-in-out;
      }
    }

    &.--error {
      .__input-field-wrapper {
        border-bottom: 1px solid #d30101;

        &::after {
          background: #d30101;
        }
      }
    }

    &.--focused {
      .__input-field-wrapper {
        &::after {
          width: 100%;
        }
      }
    }
  }
}
</style>
